<template>
  <div class="relative overflow-hidden">
    <!-- Circle in the top left corner -->
    <div
      class="absolute left-0 top-0 transform -translate-x-1/3 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
    ></div>

    <!-- Circle in the bottom left corner -->
    <div
      class="absolute left-0 bottom-0 transform -translate-x-1/3 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
    ></div>

    <!-- Circle in the top right corner -->
    <div
      class="absolute top-2 right-0 w-32 h-32 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-right-to-left"
    ></div>

    <!-- Circle in the bottom right corner -->
    <div
      class="absolute bottom-20 end-20 right-0 w-20 h-20 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-side-to-side"
    ></div>

    <!-- Background content -->
    <div
       class="p-5 space-x-6 w-full bg-gradient-to-r from-red-600 to-orange-500 min-h-screen flex justify-center items-center overflow-hidden"
    >
      <!-- Your content goes here -->
    </div>

    <!-- Add to the user_regis.vue template: -->
    <div v-if="localLoading" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]">
      <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
    </div>

    <!-- Modal -->
    <div
      id="crud-modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed inset-0 flex justify-center items-center z-50"
    >
      <div class="bg-white bg-opacity-10 backdrop-filter absolute inset-0"></div>
      <div class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[70vw] max-h-[80vh] p-6 flex flex-col">
        
        <!-- Modal header -->
        <div class="flex items-center justify-between border-b pb-4 mb-4">
          <img :src="logo" alt="Conference Logo" class="h-10 w-auto mr-8" />
          <h3 class="text-lg font-semibold text-white flex-grow break-word">
            Register for {{ recordName }}
          </h3>
        </div>

        <!-- Modal body -->
        <!-- Scrollable form content, including the disclaimer -->
    <div class="relative flex-grow overflow-auto custom-scrollbar pr-4">
      <!-- Disclaimer -->
      <p class="text-sm text-white mb-4 text-center">
        By submitting this form, you consent to Aurum Institute Ghana collecting and processing your personal data in accordance with the Data Protection Act, 2012 (Act 843). Your information will be kept confidential and used only for registration purposes. For more information click <a href="privacy_policy" target="_blank" class="text-blue-700 underline">here</a>.
      </p>

      <!-- Form -->
      <form @submit.prevent="register" :disabled="!registrationEnabled">
        <!-- Full Name Input -->
        <div class="mb-4" style="padding-left: 0.5rem;">
          <label for="full-name" class="block text-sm font-medium text-white">Full Name</label>
          <input
            v-model="fullName"
            type="text"
            id="full-name"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
            required
          />
        </div>

        <!-- Organization Input -->
        <div class="mb-4" style="padding-left: 0.5rem;">
          <label for="organization" class="block text-sm font-medium text-white">Organisation</label>
          <input
            v-model="organization"
            type="text"
            id="organization"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
            required
          />
        </div>

        <!-- Email Address Input -->
        <div class="mb-4" style="padding-left: 0.5rem;">
          <label for="email" class="block text-sm font-medium text-white">Email Address</label>
          <input
            v-model="email"
            type="email"
            id="email"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
            required
          />
        </div>

        <!-- Designation Input -->
        <div class="mb-4" style="padding-left: 0.5rem;">
          <label for="designation" class="block text-sm font-medium text-white">Designation</label>
          <input
            v-model="designation"
            type="text"
            id="designation"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
            required
          />
        </div>

        <!-- Telephone Input -->
        <div class="mb-4" style="padding-left: 0.5rem;">
          <label for="telephone" class="block text-sm font-medium text-white">Telephone Number</label>
          <input
            v-model="telephone"
            @input="filterNonNumeric"
            type="tel"
            id="telephone"
            pattern="[0-9]*"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none"
            required
          />
        </div>

        <!-- Role Input -->
        <div v-if="enablePayment" class="mb-4" style="padding-left: 0.5rem;">
          <label for="role" class="block text-sm font-medium text-white">Institution</label>
          <select v-model="role" id="role" class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-30 text-white placeholder-white focus:outline-none custom-select" :disabled="submitted" required>
            <option value="" disabled>Select your Institution</option>
            <option value="developmental_partner" class="text-black">Developmental Partner</option>
            <option value="government_representative" class="text-black">Government Representative</option>
            <option value="civil_service_organisation" class="text-black">Civil Service Organisation</option>
            <option value="academic_and_research_institution" class="text-black">Academic & Research Institution</option>
          </select>
        </div>
      </form>
    </div>

        <!-- Dynamic registration button -->
        <div class="flex-shrink-0 mt-4" style="padding-left: 0.5rem;">
          <button
            type="submit"
            @click="register"
            class="bg-red-600 text-white w-full px-8 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
          >
            <!-- {{ role === 'government_representative' || role === 'civil_service_organisation' || role === 'academic_and_research_institution' ? 'Add Reimbursement Details' : 'Register' }} -->
              <!-- Conditional button text based on enablePayment and role -->
            {{ 
              enablePayment && 
              (role === 'government_representative' || 
              role === 'civil_service_organisation' || 
              role === 'academic_and_research_institution') 
              ? 'Add Reimbursement Details' 
              : 'Register' 
            }}
          </button>
        </div>

        <!-- Display registration status -->
        <p class="text-white mt-4" v-if="message">{{ message }}</p>

        <!-- Loader -->
        <div v-if="loading" class="loader"></div>

        <!-- Footer -->
        <div class="mt-4 pt-4 border-t flex-shrink-0">
          <p class="text-sm text-white text-center mt-2">
            &copy; 2024 Aurum Institute Ghana. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/logo.png";
import { format } from 'date-fns';
import { API_URL } from '../config';

export default {
  name: "GradientBackgroundWithAdjustedCircles",
  props: {
    loading: { 
      type: Boolean, 
      default: false, 
    },
  },
  data() {
    return {
      localLoading: false,
      fullName: "",
      organization: "",
      email: "",
      telephone: "",
      designation: "",
      role: "",
      recordId: null,
      recordName: "",
      paymentRecordId: null,
      message: "",
      registrationEnabled: false,
      touched: {
        fullName: false,
        organization: false,
        email: false,
        telephone: false,
        designation: false,
        role: false,
      },
      submitted: false,
      enablePayment: false,
      logo, // Assign the imported logo
    };
  },
  mounted() {
    this.localLoading = true;
    // Remove fetchBanks since it's not needed until redirect
    this.localLoading = false;

    // Extract recordId and recordName from the route parameters
    this.recordId = decodeURIComponent(this.$route.query.recordId || "");
    this.recordName = decodeURIComponent(this.$route.query.recordName || "");
    this.paymentRecordId = decodeURIComponent(this.$route.query.paymentRecordId || "");

    this.fetchConferenceDetails();

    // Debugging logs
    console.log("Record ID:", this.recordId);
    console.log("Record Name:", this.recordName);
    console.log("Payment Record ID:", this.paymentRecordId);
  },
  methods: {
    async fetchConferenceDetails() {
    try {
      const response = await axios.get(`${API_URL}/api/records/${this.recordId}`);
      this.enablePayment = !!response.data.enablePayment;
    } catch (error) {
      console.error('Error fetching conference details:', error);
      this.enablePayment = false;
    }

    console.log("Enable Payment:", this.enablePayment);

    },
    // Method to remove non-numeric characters from the telephone input
    filterNonNumeric() {
      this.telephone = this.telephone.replace(/\D/g, "");
    },

    // Check if all required fields are filled
    checkFields() {
      this.registrationEnabled =
        this.fullName.trim() &&
        this.organization.trim() &&
        this.email.trim() &&
        // this.role.trim() &&
        this.designation.trim()&&
        this.telephone.trim();
        // If enablePayment is true, role is required
        (!this.enablePayment || this.role.trim());
    },

async register() {
  this.submitted = true;
  this.localLoading = true; // Add loading state

  if (!this.registrationEnabled) {
    return; // Prevent submission if form is not valid
  }

  console.log("Conference Record ID:", this.recordId);
  console.log("Telephone:", this.telephone);
  console.log("Existing Payment Record ID:", this.paymentRecordId);

  try {
    const response = await axios.post(`${API_URL}/api/register`, {
      fullName: this.fullName,
      organization: this.organization,
      email: this.email,
      TelNumber: this.telephone,
      designation: this.designation,
      ...(this.enablePayment && { role: this.role }),
      recordId: this.recordId,
      enablePayment: this.enablePayment,
    });

    console.log("Registration Response:", response.data);

    if (response.data.success) {
      const { linkedRecordId } = response.data;

      // Send email
      const eventDetailsResponse = await axios.get(
            `${API_URL}/api/records/${this.recordId}`
          );
          const eventDetails = eventDetailsResponse.data;

          // Format event details
          const formattedStartDate = format(
            new Date(eventDetails.startDate),
            'MMMM d, yyyy'
          );
          const formattedStartTime = format(
            new Date(eventDetails.startDate),
            'h:mm a'
          );
          const formattedEndDate = format(
            new Date(eventDetails.endDate),
            'MMMM d, yyyy'
          );
          const formattedEndTime = format(
            new Date(eventDetails.endDate),
            'h:mm a'
          );

          // Prepare email data
          const emailData = {
            fullName: this.fullName,
            email: this.email,
            conferenceName: eventDetails.name,
            startDate: formattedStartDate,
            startTime: formattedStartTime,
            endDate: formattedEndDate,
            endTime: formattedEndTime,
            venue: eventDetails.venue,
          };

          console.log("Email Data:", emailData);

          // Send email after successful registration and fetching details
          await axios.post(`${API_URL}/api/sendEventEmail`, emailData);
          console.log("Email sent successfully");

      // Redirect based on role
      if (this.role === "developmental_partner") {
        console.log("Redirecting to registration successful page");
        this.$router.replace({
          path: "/regis_successful",
          query: { recordId: this.recordId },
        });
      } else {
        console.log("Redirecting to reimbursement details page");
        this.$router.replace({
          path: "/reimbursement_details",
          query: {
            recordId: this.recordId, // Pass the main record ID
            paymentRecordId: linkedRecordId || this.paymentRecordId || undefined, // Pass the paymentRecordId if available
            fullNameOnAccount: this.fullName,  
            telephone: this.telephone,         
            email: this.email                 
          },
        });
      }
    } else {
      // Handle failure from the backend
      this.message = response.data.message || "Registration failed. Please try again.";
      this.localLoading = false;
    }
  } catch (error) {
    console.error("Error during registration:", error);
    this.message = error.response?.data?.error || "Registration failed. Please try again.";
  } finally {
    this.localLoading = false;
  }

  // Clear error message after 3 seconds
  setTimeout(() => {
    this.message = "";
  }, 3000);

  // Modify browser history to prevent back navigation
  history.replaceState(null, "", location.pathname);
}

  },
  watch: {
    // Re-validate form when inputs change
    fullName: "checkFields",
    organization: "checkFields",
    email: "checkFields",
    telephone: "checkFields",
    role: "checkFields",
  },
  beforeRouteLeave(to, from, next) {
    if (this.submitted) {
      next(false); // Block navigation if form has been submitted
    } else {
      next(); // Allow navigation
    }
  },
};
</script>


<style scoped>
.role-menu:hover {
  background-color: red;
}

.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}

.move-side-to-side {
  animation: moveSideToSide 10s ease-in-out infinite alternate;
}

.move-right-to-left {
  animation: moveRightToLeft 10s ease-in-out infinite alternate;
}

@keyframes moveSideToSide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.break-all {
  word-break: break-all;
}

.break-word {
  overflow-wrap: break-word;
}

.custom-select {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
}

.custom-select option {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  padding: 0.5rem;
}

.custom-select option:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

input:focus, 
select:focus, 
textarea:focus {
  outline: 1px solid red; /* Set the focus outline color to red */
  box-shadow: 0 0 1px red; /* Optional: Add a red glow */
}

</style>
