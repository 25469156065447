<template>
  <!-- Circle in the top left corner -->
  <div
    class="fixed left-0 top-0 transform -translate-x-1/3 z-10 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
  ></div>

  <!-- Circle in the bottom left corner -->
  <div
    class="fixed left-0 bottom-0 transform -translate-x-1/3 z-10 w-44 h-44 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg"
  ></div>

  <!-- Circle in the top right corner -->
  <div
    class="fixed top-2 right-0 z-10 w-32 h-32 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-right-to-left"
  ></div>

  <!-- Circle in the bottom right corner -->
  <div
    class="fixed bottom-20 right-20 z-10 w-20 h-20 bg-gradient-to-b from-orange-500 to-red-600 rounded-full shadow-lg move-side-to-side"
  ></div>

  <!-- Background content -->
  <div
    class="fixed inset-0 overflow-hidden bg-gradient-to-r from-red-600 to-orange-500 flex justify-center items-center"
  >
    <!-- Your content goes here -->
  </div>

  <!-- Modal -->
  <div
    id="crud-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed inset-0 overflow-y-auto flex justify-center items-center z-50"
  >
    <div
      class="bg-white bg-opacity-10 backdrop-filter absolute inset-0"
    ></div>
    <div
      class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[60vw] max-w-sm p-6 flex flex-col justify-between"
    >
      <!-- Modal content -->
      <div class="relative flex items-center justify-center">
        <!-- Modal header -->
        <div class="text-center">
          <h3 class="text-2xl font-bold text-white">
            Congratulations!
          </h3>
          <div class="mt-4"></div>
          <p class="text-white">You have successfully checked In.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
  this.localLoading = false;
},
};
</script>

<style scoped>
.move-side-to-side {
  animation: moveSideToSide 10s ease-in-out infinite alternate;
}

.move-right-to-left {
  animation: moveRightToLeft 10s ease-in-out infinite alternate;
}

@keyframes moveSideToSide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
