<template>
  <RouterView />
</template>

<script>
import { provide, reactive } from "vue";

export default {
  setup() {
    const notifications = reactive([]);

    provide("notifications", notifications);

    return {};
  },
};
</script>
