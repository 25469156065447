<template>
  <div class="relative overflow-hidden"></div>

  <!-- Background content -->
  <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-r from-red-600 to-orange-500 h-[100vh] flex justify-center items-center"></div>

  <!-- Loading Spinner Overlay -->
  <div
    v-if="isLoading"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[60]"
  >
    <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
  </div>

  <!-- Modal -->
  <div id="crud-modal" tabindex="-1" aria-hidden="true" class="fixed inset-0 flex justify-center items-center z-50">
    <div class="bg-white bg-opacity-10 backdrop-filter absolute inset-0"></div>

    <div class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[60vw] h-6/6 max-h-[calc(80vh-0rem)] p-6 flex flex-col justify-between overflow-hidden">
      
      <!-- Modal content -->
      <div class="mb-4" style="padding-left: 0.5rem;">
        
        <!-- Modal header -->
        <div class="flex items-center justify-between border-b pb-4 mb-4">
          <img :src="logo" alt="Logo" class="h-10 w-auto mr-8" />
          <h3 class="text-lg font-semibold text-white flex-grow break-word">Check-In</h3>
        </div>

        <!-- Modal body -->
        
        <form @submit.prevent="handleCheckIn" :disabled="!registrationEnabled || submitted" class="flex flex-col space-y-4">

          <!-- Telephone Number Input -->
          <div>
            <label for="telephone-number" class="block text-sm font-medium text-white">Telephone Number</label>
            <input 
            v-model="telephoneNumber" 
            @input="filterNonNumeric"
            type="text" 
            id="telephone-number" 
            pattern="[0-9]*"
            class="mt-1 block w-full rounded-md border-none bg-white bg-opacity-20 text-white placeholder-white focus:outline-none" 
            :disabled="submitted" 
            required />
          </div>

          <!-- Check-in button -->
          <button type="submit" class="bg-red-600 text-white w-full px-8 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700" :disabled="!registrationEnabled || submitted">Check-In</button>
        </form>
      </div>

       <!-- Footer -->
      <div class="border-t mt-4 pt-4">
        <p class="text-sm text-white text-center">
          &copy; 2024 Aurum Institute Ghana. All rights reserved.
        </p>

        <!-- Display check-in status -->
        <p class="text-white mt-4" v-if="message">{{ message }}</p>
      </div>

    </div>
  </div>
</template>


<script>
import axios from "axios";
import logo from "@/assets/logo.png";
import { API_URL } from '../config';

export default {
  // 1. Define props to receive from parent
  props: {
    recordId: {
      type: String,
      required: true,
    },
    paymentRecordId: {
      type: String,
      required: true,
    },
    checkInDate: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      logo: logo,
      telephoneNumber: "",
      submitted: false,
      registrationEnabled: false,
      message: "",
      recordType: "",
      isLoading: false,
    };
  },

  watch: {
    telephoneNumber: "checkFields",
    checkInDate: "checkFields", // Watcher if checkInDate can change
  },

  mounted() {
    this.checkFields();
  },

  methods: {
    checkFields() {
      this.registrationEnabled =
        this.telephoneNumber !== "" && this.checkInDate !== "";
    },

    async handleCheckIn() {
      console.log("Check-in button clicked"); // Debug log

      if (!this.registrationEnabled) {
        this.message = "Please fill in all required fields.";
        return;
      }

      this.isLoading = true;  // Show loader
      this.submitted = true;
      this.message = "";

      try {
        const response = await axios.post(
          `${API_URL}/api/checkInParticipant`,
          {
            TelNumber: this.telephoneNumber,
            checkInDate: this.checkInDate,
            recordId: this.paymentRecordId, // Using paymentRecordId as per original logic
          }
        );

        if (response.data.exists) {
          this.message =
            "Check-in already exists for this telephone number on the selected date.";
            this.isLoading = false; // Hide loader on error
        } else if (response.status === 200) {
          this.message = "Check-in successful!";
          this.$router.push("/checkin_successful");
          this.submitted = true;
        } else {
          this.message =
            response.data.error || "An error occurred during check-in.";
            this.isLoading = false;
        }
      } catch (error) {
        console.error("Error during check-in:", error);
        this.message =
          "Unable to complete the check-in. Please try again later.";
          this.isLoading = false;
      }
      this.submitted = false;
    },

    // Method to remove non-numeric characters from the telephone input
    filterNonNumeric() {
      this.telephoneNumber = this.telephoneNumber.replace(/\D/g, "");
    },
  },
};
</script>

<style scoped>
input:focus, 
select:focus, 
textarea:focus {
  outline: 1px solid red; 
  box-shadow: 0 0 1px red; 
}
</style>