<template>
  <div class="w-full h-[100vh] bg-gradient-to-b from-orange-100 to-red-100 flex flex-col">
    <!-- Navigation Bar -->
    <menu_bar />
    <!-- Main Content Area -->
    <div class="flex-grow p-3">
      <div class="w-full h-full max-h-[88vh] mx-auto bg-white rounded-lg shadow-md flex flex-col">
        <!-- Header -->
        <header class="p-4 border-b">
          <h2 class="text-2xl font-bold pl-4" style="color: #cf2027;">
            Reimbursement Records
          </h2>
        </header>
        <!-- Main Content Area -->
        <main class="flex-grow overflow-y-auto relative custom-scrollbar">
          <!-- Payment List -->
          <div class="p-6">
            <ul v-if="payments.length > 0" class="divide-y divide-gray-200">
              <li
                v-for="payment in payments"
                :key="payment.id"
                class="py-4 hover:bg-red-100"
                @contextmenu.prevent="showContextMenu(payment, $event)"
              >
                <div class="flex justify-between">
                  <div class="flex-1">
                    <p
                      class="text-sm font-semibold"
                      @mouseover="hoveredPayment = payment"
                      @mouseleave="hoveredPayment = null"
                    >
                      <span v-html="highlightedOrName(payment)"></span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p v-else class="text-gray-500">
              No upcoming records of reimbursements available
            </p>
          </div>
          <!-- Context Menu -->
          <div
            v-if="showMenu"
            ref="contextMenu"
            class="absolute z-10 bg-white shadow-md p-2 rounded-lg context-menu"
            :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
          >
            <ul class="py-2">
              <li
                @click="viewReimbursementDetails(clickedPayment)"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                View Reimbursement Details
              </li>
            </ul>
          </div>
        </main>
        <!-- Footer Navigation -->
        <nav_bar />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { API_URL } from '../config';

// Import the Pinia Search Store
import { useSearchStore } from "@/stores/searchStore";

import nav_bar from "@/components/nav_bar.vue";
import menu_bar from "@/components/menu_bar.vue";

const router = useRouter();

const payments = ref([]);
const hoveredPayment = ref(null);
const clickedPayment = ref(null);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

// Access the searchStore
const searchStore = useSearchStore();

// Helper to check if there's a "highlightedName" for this record
function highlightedOrName(payment) {
  const found = searchStore.getFilteredRecords().find(
    (item) => item.id === payment.id && item.type === "payment"
  );
  // If found, return the store's "highlightedName". Otherwise return the original name.
  return found?.highlightedName || payment.name;
}

// Fetch payments sorted by date
const fetchPayments = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/payments/sortedByDate`);
    console.log("API Full Response:", response);

    // Check if records field exists and is an array
    if (response.data && Array.isArray(response.data.records)) {
      payments.value = response.data.records.map((payment) => ({
        recordId: payment.id, // Ensure this matches the field name in your API response
        name: payment.name,
        date: payment.startDate,
        endDate: payment.endDate,
        venue: payment.venue,
        type: payment.type,
      }));
      console.log("Mapped Payments:", payments.value);
    } else {
      console.error("Unexpected response format: 'records' is not an array", response.data);
      payments.value = [];
    }
  } catch (error) {
    console.error("Error fetching payments:", error);
    if (error.response) {
      console.log("Axios Error Details:", error.response);
    }
  }
};

// Fetch details of a specific payment
const fetchPaymentDetails = async (recordId) => {
  try {
    if (!recordId) {
      console.error("Record ID is required to fetch details.");
      return;
    }

    console.log(`Fetching payment details for record ID: ${recordId}`);

    const response = await axios.get(`${API_URL}/api/records/${recordId}`);

    if (response.data) {
      // Explicitly map 'id' to 'recordId'
      clickedPayment.value = {
        recordId: response.data.id, // Mapping 'id' to 'recordId'
        name: response.data.name,
        date: response.data.startDate,
        endDate: response.data.endDate,
        venue: response.data.venue,
        type: response.data.type,
        enableCheckIn: response.data.enableCheckIn,
        // Include other necessary fields as needed
      };
      console.log("Fetched Payment Details:", clickedPayment.value);
    } else {
      console.warn("No data returned for record ID:", recordId);
      clickedPayment.value = null;
    }
  } catch (error) {
    console.error("Error fetching payment details:", error);
  }
};

// Show the context menu for a selected payment
const showContextMenu = async (payment, event) => {
  try {
    console.log("Clicked Payment:", payment);
    clickedPayment.value = payment;
    if (!payment?.recordId) { 
      console.error("Payment ID is required to show the context menu.");
      return;
    }

    const paymentDetails = await fetchPaymentDetails(payment.recordId);
    if (paymentDetails) {
      clickedPayment.value = paymentDetails; 
    }

    const container = document.querySelector(".overflow-y-auto");
    const containerRect = container.getBoundingClientRect();

    menuPosition.value = {
    x: event.clientX - containerRect.left + container.scrollLeft,
    y: event.clientY - containerRect.top + container.scrollTop,
    };
    
    showMenu.value = true;

    // Add a global click listener to hide the context menu
    window.addEventListener("click", hideContextMenu);
  } catch (error) {
    console.error("Error showing context menu:", error);
  }
};

// Hide the context menu
const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener("click", hideContextMenu);
};

// Navigate to reimbursement details page
const viewReimbursementDetails = (payment) => {
  if (!payment || !payment.recordId) {
    console.error('No record ID found for this payment');
    return;
  }

  router.push({ 
    path: "/Info_reimbursements", 
    query: { 
      recordId: payment.recordId,
      recordName: payment.name,
      startDate: payment.date,
      endDate: payment.endDate,
      venue: payment.venue,
      enableCheckIn: payment.enableCheckIn
    } 
  });
};

// Fetch payments on component mount
onMounted(fetchPayments);

// Clean up global event listeners when the component is unmounted
onUnmounted(() => {
  window.removeEventListener("click", hideContextMenu);
});
</script>

<script>
export default {
  name: "App",

  methods: {
    goToEventPage() {
      this.$router.push("/Event_page");
    },

    goToHomePage() {
      this.$router.push("/");
    },

    goToInfoReimbursements() {
      this.$router.push("/info_reimbursements");
    },
  },
};
</script>

<style>
/* Add your component-specific styles here */
.payment-name {
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0; /* Double the padding to increase spacing */
}

.context-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 10px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #fde9e9;
}

/* Modal styles */
.modal-overlay {
  z-index: 50;
}

.modal-container {
  position: relative;
  z-index: 50;
  padding: 20px;
}

/* Added padding for the context menu button */
.context-menu button {
  padding-top: 10px; /* Adjust as needed */
  padding-bottom: 10px; /* Adjust as needed */
  padding-right: 10px;
  padding-left: 10px;
}
</style>