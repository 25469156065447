// src/stores/searchStore.js (Updated)
import { defineStore } from "pinia";

export const useSearchStore = defineStore("search", {
  state: () => ({
    query: "", // Search query
    records: [], // All records (conferences and payments)
  }),
  actions: {
    setQuery(newQuery) {
      this.query = newQuery;
    },
    setRecords(newRecords) {
      this.records = newRecords;
    },
    getFilteredRecords() {
      if (!this.query.trim()) {
        return this.records; // Return all records if query is empty
      }
      const regex = new RegExp(`(${this.query})`, "gi");
      return this.records.map((record) => ({
        ...record,
        highlightedName: record.name.replace(
          regex,
          `<mark class="highlight">$1</mark>`
        ),
      }));
    },
  },
});
