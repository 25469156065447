<template>
  <!-- Background content -->
  <div
    class="p-5 space-x-6 w-[100vw] bg-gradient-to-r from-red-600 to-orange-500 h-[100vh] flex justify-center items-center"
  >
    <!-- Modal -->
    <div
      id="crud-modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed inset-0 flex justify-center items-center z-50"
    >
      <div class="bg-white bg-opacity-5 backdrop-filter absolute inset-0"></div>
      <div class="relative bg-white bg-opacity-30 rounded-lg shadow-lg w-[70vw] max-h-[80vh] p-6 flex flex-col">
        <!-- Modal header -->
        <div class="flex items-center justify-between border-b pb-4 mb-4">
          <h3 class="text-lg font-bold text-white flex-grow break-word">
            Privacy Policy
          </h3>
        </div>

        <!-- Modal body -->
        <div class="relative flex-grow overflow-auto custom-scrollbar pr-4 privacy-policy text-white">
          <p>
            Aurum Institute Ghana ("we," "us," or "our") is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your personal data in accordance with the Data Protection Act, 2012 (Act 843) of Ghana.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We collect personal information that you provide to us for the purpose of registration and payment. This may include:
          </p>
          <ul>
            <li><strong>Personal Identification Information:</strong> Name, and organisation.</li>
            <li><strong>Contact Information:</strong> Email address, and phone number.</li>
            <li><strong>Payment Information:</strong> Bank account details and other payment-related information.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>
            The personal information we collect will be used solely for the following purposes:
          </p>
          <ul>
            <li>To process your registration and verify your identity.</li>
            <li>To facilitate and process payments for our services.</li>
            <li>To communicate with you regarding your registration and payment status.</li>
            <li>To provide you with information and updates about our services.</li>
            <li>To comply with legal and regulatory requirements.</li>
          </ul>

          <h2>3. Disclosure of Your Information</h2>
          <p>
            We may share your personal information with third parties only under the following circumstances:
          </p>
          <ul>
            <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in processing registrations and payments.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to a legal request.</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>
            We take appropriate measures to ensure the security of your personal information. This includes implementing physical, technical, and administrative safeguards to protect against unauthorized access, use, or disclosure.
          </p>

          <h2>5. Data Retention</h2>
          <p>
            We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected and to comply with legal and regulatory requirements.
          </p>

          <h2>6. Your Rights</h2>
          <p>
            Under the Data Protection Act of Ghana, you have the right to:
          </p>
          <ul>
            <li>Access your personal data and request a copy.</li>
            <li>Correct any inaccuracies in your personal data.</li>
            <li>Request the deletion of your personal data, subject to legal and contractual restrictions.</li>
            <li>Object to the processing of your personal data for certain purposes.</li>
          </ul>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the effective date.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or our data protection practices, please contact our data protection officer. For more information on the Data Protection Act of Ghana click here:
          </p>
          <p>
            Aurum Institute Ghana<br />
            Name: Mr. George Amoo Adjei<br />
            Email: Gadjei@auruminstitute.org<br />
          </p>

          <p>Effective Date: </p>
        </div>

        <!-- Footer -->
        <div class="mt-4 pt-4 border-t flex-shrink-0">
          <p class="text-sm text-white text-center mt-2">
            &copy; 2024 Aurum Institute Ghana. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}

.privacy-policy {
  padding: 20px;
  line-height: 1.6;
}

.privacy-policy h2 {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 20px;
  color: #f3f3f3;
}

.privacy-policy p, 
.privacy-policy ul {
  font-size: 1em;
  margin-bottom: 10px;
  color: #f3f3f3;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy ul li {
  margin-bottom: 5px;
}

.privacy-policy strong {
  font-weight: bold;
  color: #ffffff;
}
</style>
