<template>
  <div class="p-5 w-full bg-gradient-to-r from-red-600 to-orange-500 min-h-screen flex flex-col justify-center items-center space-y-8">
    <!-- No Check-in Details Available -->
    <div v-if="!recordId" class="text-white text-2xl font-bold">
      No check-in details available.
    </div>
    <!-- Main Content -->
    <div v-else>
      <!-- Conference Header -->
      <header>
        <div class="text-center">
          <h1 class="text-4xl font-bold text-white mb-2">{{ record.name }}</h1>
          <p class="text-lg text-white pb-4">
            {{ formatDate(record.startDate) }} - {{ formatDate(record.endDate) }}
          </p>
        </div>
      </header>

      <!-- List of Conference Days -->
      <main class="custom-scrollbar">
        <ul class="flex flex-wrap justify-center gap-4">
          <li
            v-for="day in days"
            :key="day.date"
            class="p-4 w-48 rounded-lg text-center border border-white shadow-lg"
            :class="isToday(day.date) ? 'bg-red-900 text-white' : 'bg-gray-400 text-white opacity-50'"
          >
            <span class="block text-xl font-semibold">{{ formatDate(day.date) }}</span>
            <button
              @click="goToCheckInPage(day.date)"
              :disabled="!isToday(day.date)"
              class="mt-4 px-4 py-2 bg-red-700 text-white-500 rounded-lg font-medium hover:bg-red-800 hover:text-white disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-300"
            >
              Check In
            </button>
          </li>
        </ul>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../config';

export default {
  data() {
    return {
      recordId: null, // Initialize with null to check availability
      record: {
        name: "",
        startDate: "",
        endDate: "",
      },
      days: [],
    };
  },
  methods: {
    async fetchRecordDetails() {
      try {
        if (!this.recordId) {
          console.log("No record ID provided. Skipping data fetch.");
          return; // Exit if no recordId is available
        }

        // Replace the URL with your actual API endpoint
        const response = await axios.get(`${API_URL}/api/records/${this.recordId}`);

        // Update the record data with the response
        this.record = response.data;

        // Reinitialize the days after fetching record details
        this.initializeDays();
      } catch (error) {
        console.error("Error fetching record details:", error);
      }
    },

    initializeDays() {
      if (!this.record.startDate || !this.record.endDate) return;

      const startDate = new Date(this.record.startDate);
      const endDate = new Date(this.record.endDate);
      let currentDate = startDate;

      this.days = []; // Clear previous days
      while (currentDate <= endDate) {
        this.days.push({
          date: currentDate.toISOString().split("T")[0],
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },

    isToday(date) {
      const today = new Date().toISOString().split("T")[0];
      return date === today;
    },

    goToCheckInPage(date) {
      if (!this.recordId) {
        console.error("Missing recordId. Cannot navigate to check-in page.");
        return;
      }

      this.$router.push({
      name: "check_in",
      query: { 
      recordId: this.recordId, 
      paymentRecordId: this.paymentRecordId, 
      checkInDate: date 
    },
  });
},

  },
  mounted() {
    // Retrieve recordId from route 
    this.recordId = this.$route.query.recordId || null;

    // Retrieve payment recordId from route
    this.paymentRecordId = this.$route.query.paymentRecordId || null;

    // Fetch record details only if recordId is available
    this.fetchRecordDetails();
  },
};
</script>

<style scoped>
.custom-scrollbar {
  overflow-y: auto;
  max-height: 70vh;
  padding-right: 1rem;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}
</style>
