<template>
  <div class="w-full h-[100vh] bg-gradient-to-b from-orange-100 to-red-100 flex flex-col">
    <!-- Navigation Bar Component -->
    <menu_bar />
    <!-- Spacing Between the Top Bar and Main Container -->
    <div class="flex-grow p-3">
      <!-- Main Content Container -->
      <div class="w-full h-full max-h-[88vh] mx-auto bg-white rounded-lg shadow-md flex flex-col">
        <!-- Modal Component -->
        <modal_page v-if="isModalOpen" @close="toggleModal"  @conferenceCreated="addConference" />
        <!-- Header -->
        <header class="p-4 border-b">
          <div class="relative">
            <modal_page/>
            <h2 class="text-2xl font-bold pl-4" style="color: #cf2027;">Conference Records</h2>
          </div>
        </header>
        <!-- Main Content Area with Scrollbar -->
        <main class="flex-grow overflow-y-auto relative custom-scrollbar">
          <div class="p-6">
            <ul v-if="sortedConferences.length > 0" class="divide-y divide-gray-200">
              <li
                v-for="conference in sortedConferences"
                :key="conference.id"
                class="py-4 hover:bg-red-100"
                @contextmenu.prevent="showContextMenu(conference, $event)"
              >
                <div class="flex justify-between">
                  <div class="flex-1">
                    <p
                      class="text-sm font-semibold"
                      @mouseover="hoveredConference = conference"
                      @mouseleave="hoveredConference = null"
                    >
                      <span v-html="highlightedOrName(conference)"></span>
                    </p>
                  </div>
                  <div class="flex-1 text-right">
                    <p class="text-sm text-gray-500">{{ formatDate(conference.date) }}</p>
                    <p
                    class="text-xs font-semibold"
                    :class="{
                      'text-green-500': getConferenceStatus(conference.date, conference.endDate) === 'Upcoming',
                      'text-blue-500': getConferenceStatus(conference.date, conference.endDate) === 'Ongoing',
                      'text-red-500': getConferenceStatus(conference.date, conference.endDate) === 'Past'
                    }"
                  >
                    {{ getConferenceStatus(conference.date, conference.endDate) }}
                  </p>
                  </div>
                </div>
              </li>
            </ul>
            <p v-else class="text-gray-500">No conferences available</p>
          </div>
          <!-- Context Menu -->
          <div
            v-if="showMenu"
            ref="contextMenu"
            class="absolute z-10 bg-white shadow-md p-2 rounded-lg context-menu"
            :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
          >
            <ul class="py-2">
              <li
                @click="copyConferenceLink"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                Copy Conference Link
              </li>
              <li
                @click="viewParticipantsInfo(clickedConference)"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                View Conference Info
              </li>
              <li
                @click="deleteConference(clickedConference.id)"
                class="hover:bg-red-100 px-4 py-2 cursor-pointer"
              >
                Delete Conference
              </li>
              <!-- Check-in Link Option -->
              <li v-if="enableCheckIn" @click="generateCheckinLink">
              Copy Check-in Link
            </li>
            <li v-else>
              <span>Check In is not Available</span>
            </li>
            <!-- Add this inside the <ul class="py-2"> in the context menu -->
            <li
              @click="viewParticipantsData(clickedConference)"
              class="hover:bg-red-100 px-4 py-2 cursor-pointer"
            >
              View Participants Data
            </li>
            </ul>
          </div>
        </main>
        <!-- Nav bar -->
        <nav_bar />
      </div>
    </div>
  </div>
</template>

<!-- Note that conferences and reimbursements (payments) are now part of the record table 
    and are differenciated in the table using the type column -->

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { API_URL } from '../config';
import { FRONTEND_URL } from '../config';


// Import components
import modal_page from "@/components/modal_page.vue";
import nav_bar from "@/components/nav_bar.vue";
import menu_bar from "@/components/menu_bar.vue";

// Import the Pinia Search Store
import { useSearchStore } from '@/stores/searchStore';
import { useNotificationStore } from "@/stores/notificationStore"; // <--- ADDED

const router = useRouter();

// Reactive references
const conferences = ref([]);
const hoveredConference = ref(null);
const clickedConference = ref(null);
const isModalOpen = ref(false);
const showMenu = ref(false);
const menuPosition = ref({ x: 0, y: 0 });

// Access searchStore
const searchStore = useSearchStore();
const notificationStore = useNotificationStore(); // <--- ADDED

// Toggle modal visibility
const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value;
};

// Fetch conferences by date from the API 
const fetchConferences = async () => {   
  try {     
    const response = await axios.get(`${API_URL}/api/by_date`);
    const records = response.data.records || response.data;
    conferences.value = records
      .filter(record => record.type === 'conference')
      .map(conference => {
      
        const paymentRecordId = conference.paymentRecordId || null;
        // const enableCheckIn = conference.enableCheckIn === 1;
        // const enablePayment = conference.enablePayment === 1;

        console.log(`Detailed Payment Record Check - Conference ID: ${conference.id}, Payment Record ID: ${paymentRecordId}`);
        
        return {
          id: conference.id,
          name: conference.name,
          date: conference.startDate,
          enableCheckIn: conference.enableCheckIn === 1 || conference.enableCheckIn === true,
          enablePayment: conference.enablePayment === 1 || conference.enablePayment === true,
          endDate: conference.endDate,
          venue: conference.venue,
          paymentRecordId: conference.paymentRecordId,
        };
      });
  } catch (error) {
    console.error('Error Details:', error.response ? error.response.data : error.message);
  }
};

// Determine if a conference is upcoming
const getConferenceStatus = (startDate, endDate) => {
  const currentDate = new Date();
  const conferenceStartDate = new Date(startDate);
  const conferenceEndDate = new Date(endDate);
  
  if (currentDate < conferenceStartDate) {
    return 'Upcoming';
  } else if (currentDate >= conferenceStartDate && currentDate <= conferenceEndDate) {
    return 'Ongoing';
  } else {
    return 'Past';
  }
};

// Format a date for display
const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

// Sort conferences: upcoming first, then past
const sortedConferences = computed(() => {
  return conferences.value.slice().sort((a, b) => {
    const dateAStart = new Date(a.date);
    const dateBStart = new Date(b.date);

    const statusA = getConferenceStatus(a.date, a.endDate);
    const statusB = getConferenceStatus(b.date, b.endDate);

    // Priority: Ongoing > Upcoming > Past
    const statusPriority = {
      'Ongoing': 2,
      'Upcoming': 1,
      'Past': 0
    };

    if (statusPriority[statusA] !== statusPriority[statusB]) {
      return statusPriority[statusB] - statusPriority[statusA];
    }

    // If status is the same, sort by date
    return dateAStart - dateBStart;
  });
});

// Check if the clicked conference has check-in information
const enableCheckIn = computed(() => {
  return clickedConference.value ? clickedConference.value.enableCheckIn : false;
});

// Show context menu
const showContextMenu = (conference, event) => {
  console.log("Conference Data:", conference);
  console.log("Payment Record ID:", conference.paymentRecordId);
  event.preventDefault();

  clickedConference.value = conference;
  console.log("Clicked Conference:", clickedConference.value);

  const container = document.querySelector(".overflow-y-auto");
  const containerRect = container.getBoundingClientRect();

  menuPosition.value = {
    x: event.clientX - containerRect.left + container.scrollLeft,
    y: event.clientY - containerRect.top + container.scrollTop,
  };

  showMenu.value = true;
  window.addEventListener('click', hideContextMenuOutside);
};

function highlightedOrName(conference) {
  const found = searchStore.getFilteredRecords().find(
    item => item.id === conference.id && item.type === 'conference'
  );
  return found?.highlightedName || conference.name;
}

// Hide context menu
const hideContextMenu = () => {
  showMenu.value = false;
  window.removeEventListener('click', hideContextMenuOutside);
};

// Hide context menu
const hideContextMenuOutside = (event) => {
  const contextMenu = document.querySelector('.context-menu');
  if (contextMenu && !contextMenu.contains(event.target)) {
    hideContextMenu();
  }
};    

const copyConferenceLink = () => {
  if (clickedConference.value) {
    // Base conference link
    let conferenceLink = `${FRONTEND_URL}/user_regis?recordId=${clickedConference.value.id}&recordName=${encodeURIComponent(clickedConference.value.name)}`;

    // Check if the conference has an associated paymentRecordId
    if (clickedConference.value.paymentRecordId) {
      conferenceLink += `&paymentRecordId=${clickedConference.value.paymentRecordId}`;
    }

    // Copy the link to the clipboard
    navigator.clipboard.writeText(conferenceLink).then(() => {
      alert("Conference link copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy link:", err);
      alert("Failed to copy the link. Please try again.");
    });
  }

  // Hide the context menu after copying
  hideContextMenu();
};

// Generate check-in link using paymentRecordId
const generateCheckinLink = () => {
  if (clickedConference.value && clickedConference.value.paymentRecordId) {
    // Generate the check-in link using paymentRecordId
    let checkinLink = `${FRONTEND_URL}/checkin_overview?recordId=${clickedConference.value.id}&paymentRecordId=${clickedConference.value.paymentRecordId}`;

    // Copy the link to the clipboard
    navigator.clipboard.writeText(checkinLink)
      .then(() => {
        alert("Check-in link copied to clipboard!");
        console.log("Generated Check-in Link:", checkinLink);
      })
      .catch(err => {
        console.error("Failed to copy link:", err);
        alert("Failed to copy the check-in link. Please try again.");
      });
  } else {
    // Alert the user if paymentRecordId is not available
    alert("Check-in link cannot be generated. PaymentRecordId is missing.");
  }

  // Hide the context menu
  hideContextMenu();
};

// Define the viewParticipantsInfo method
const viewParticipantsInfo = (conference) => {
  console.log("Navigating with Record ID:", conference.id);
  console.log("Navigating with Record Name:", conference.name);
  // Navigate to the info_page, passing recordId and recordName as query parameters
  router.push({
    path: '/info_page', // Use the correct path to your info_page
    query: {
      recordId: conference.id,
      recordName: conference.name,
      startDate: conference.date,
      endDate: conference.endDate,
      venue: conference.venue,
    },
  });
};

// Delete conferences
const deleteConference = async (id) => {
  try {
    const confirmDelete = confirm("Are you sure you want to delete this conference?");
    if (!confirmDelete) return;

    await axios.delete(`${API_URL}/api/${id}`);
    conferences.value = conferences.value.filter((conference) => conference.id !== id);

    alert("Conference deleted successfully!");
    // Added
    this?.$notify?.({
      type: 'deleted',
      message: 'Conference was deleted successfully!',
    });
    notificationStore.addNotification(
      'deleted',
      'Conference was deleted successfully!'
    );

  } catch (error) {
    console.error("Error deleting conference:", error);
    alert("Failed to delete the conference. Please try again.");
  }

  hideContextMenu();
};

const viewParticipantsData = (conference) => {
  router.push({
    path: '/participants_data',
    query: {
      recordName: conference.name,
      recordId: conference.id,
      paymentRecordId: conference.paymentRecordId || '',
    },
  });
  hideContextMenu();
};

const addConference = (newConference) => {
  // Prepend the new conference to the list
  conferences.value.unshift(newConference);

  // Debugging to verify the new conference is added
  console.log("Updated conferences list:", conferences.value);
};


onMounted(fetchConferences);
</script>

<style>
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}
</style>