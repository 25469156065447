<template>
  <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-b from-orange-100 to-red-100 h-[100vh] flex justify-center items-center">
    <div class="w-full bg-white p-10 rounded-lg shadow-md h-96 overflow-y-auto relative" :style="{ height: '95%' }">
      <button @click="goBack" class="absolute top-4 left-4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-6 w-6 text-gray-500">
          <path fill="currentColor" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        </svg>
      </button>
      <button @click="downloadData" class="absolute top-4 right-10 flex flex-col items-center text-gray-500">
        <i class="pt-1 fa-regular fa-circle-down" style="color: #5c5c5c;"></i>
      </button>

      <!-- Activity Info Section -->
      <div class="mb-5 text-left mt-2">
        <h2 class="text-sm ">Activity Title: {{ localRecordName }}</h2>
        <p class="text-sm ">Date: {{ formattedDateRange }}</p>
        <p class="text-sm ">Venue: {{ venue }}</p>
      </div>

      <!-- Activity Info Section -->
      <div class="mb-5 text-center mt-2">
        <h2 class="text-sm font-semibold">PARTICIPANT REGISTRATION FORM</h2>
      </div>

      <!-- Table Section -->
      <table class="w-full border-collapse border border-gray-200 mt-8">
        <thead>
          <tr>
            <th class="px-4 py-2 border border-gray-200">Full Name</th>
            <th class="px-4 py-2 border border-gray-200">Organisation</th>
            <th class="px-4 py-2 border border-gray-200">Email Address</th>
            <th class="px-4 py-2 border border-gray-200">Designation</th>
            <th class="px-4 py-2 border border-gray-200">Tel Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attendee, index) in attendees" :key="index">
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Fullname }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Organisation }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.EmailAddress }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.Designation }}</td>
            <td class="px-4 py-2 border border-gray-200">{{ attendee.TelNumber }}</td>
          </tr>
          <tr v-if="attendees.length === 0">
            <td colspan="4" class="px-4 py-2 text-center">No participants found for this conference.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { API_URL } from '../config';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {
  name: "Info_page",
  props: ['recordId', 'recordName'],
  data() {
    return {
      attendees: [],
      isLoading: true,
      localRecordId: null,
      localRecordName: null,
      startDate: null,
      endDate: null,
      venue: null,
    };
  },
  computed: {
    formattedDateRange() {
      if (this.startDate && this.endDate) {
        return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
      }
      return "N/A";
    },
  },
  mounted() {
    const { recordId, recordName, startDate, endDate, venue } = this.$route.query;

    if (recordId) {
      this.localRecordId = recordId;
      this.localRecordName = recordName;
      this.startDate = startDate;
      this.endDate = endDate;
      this.venue = venue;
      this.fetchAttendees(recordId);
    } else {
      console.error('Record ID is missing');
    }
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();

      return `${this.getDayWithOrdinal(day)} ${month} ${year}`;
    },
    getDayWithOrdinal(day) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    },
    fetchAttendees(recordId) {
      this.isLoading = true;
      axios.get(`${API_URL}/api/participants/${recordId}`)
        .then(response => {
          this.attendees = response.data;
        })
        .catch(error => {
          console.error('Error fetching attendees:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    downloadData() {
      const docDefinition = {
        content: [
          {
            text: `Activity Title: ${this.localRecordName || "N/A"}`,
            style: 'subheader',
            margin: [0, 0, 0, 5], // Adds spacing after the header
          },
          {
            text: `Date: ${this.formattedDateRange}`,
            style: 'subheader',
            margin: [0, 0, 0, 5],
          },
          {
            text: `Venue: ${this.venue || "N/A"}`,
            style: 'subheader',
            margin: [0, 0, 0, 5],
          },
          {
            text: "PARTICIPANT REGISTRATION FORM",
            bold: true,
            alignment: 'center',
            margin: [0, 10, 0, 10], // Adds spacing above and below the title
          },
          {
            table: {
              headerRows: 1,
              widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                ['Full Name', 'Organisation', 'Email Address', 'Designation', 'Tel Number'],
                ...this.attendees.map(attendee => [
                  attendee.Fullname || "N/A",
                  attendee.Organisation || "N/A",
                  attendee.EmailAddress || "N/A",
                  attendee.Designation || "N/A",
                  attendee.TelNumber || "N/A",
                ]),
              ],
            },
          },
        ],
      };
      const pdfName = this.localRecordName ? `${this.localRecordName}.pdf` : 'attendees.pdf';
      pdfMake.createPdf(docDefinition).download(pdfName);
    },
  },
};
</script>


