<template>
  <!-- Modal toggle (Button with expanding hover effect) -->
  <button
    @click="toggleModal"
    class="absolute top-25 right-6 fa-solid fa-circle-plus fa-2x"
    style="
      color: #cf2027;
      display: flex;
      justify-content: right;
      align-items: center;
    "
    type="button"
  ></button>

  <!-- Main modal -->
  <div
    v-if="modalOpen"
    class="fixed top-4 right-6 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-4xl mx-auto">
      <!-- Modal content -->
      <div
        class="relative bg-white rounded-lg shadow-lg overflow-y-auto max-h-full"
      >
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-xl font-semibold text-red-600">
            {{ isConference ? "Create a Conference" : "Create a Payment" }}
          </h3>
          <button
            @click="toggleModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          >
            X
            <span class="sr-only">Close modal</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <div class="col-span-2">
            <!-- Type selector -->
            <!-- <div class="mb-4">
              <label for="type" class="block text-sm font-medium text-gray-700"
                >Type</label
              >
              <select
                id="type"
                v-model="isConference"
                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
              >
                <option :value="true">Conference</option>
                <option :value="false">Payment</option>
              </select>
            </div> -->

            <!-- Common input fields -->
            <div class="mb-4">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <input
                type="text"
                id="name"
                v-model="name"
                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                :placeholder="
                  isConference ? 'Enter conference name' : 'Enter payment name'
                "
              />
            </div>

            <!-- Conditional fields for Conference -->
            <template v-if="isConference">
              <!-- Duration for Conference -->
              <div class="mb-4">
                <label
                  for="duration"
                  class="block text-sm font-medium text-gray-700"
                  >Duration</label
                >
                <div class="pt-2 flex">
                  <flat-pickr
                    v-model="startDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="Start date and time"
                    :config="conferenceConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                  <span class="mx-4 text-gray-500">to</span>
                  <flat-pickr
                    v-model="endDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="End date and time"
                    :config="conferenceConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                </div>
              </div>

              <!-- Conference-specific fields -->
              <div class="mb-4">
                <label
                  for="venue"
                  class="block text-sm font-medium text-gray-700"
                  >Venue</label
                >
                <input
                  type="text"
                  id="venue"
                  v-model="venue"
                  class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                  placeholder="Enter conference venue"
                />
              </div>

              <!-- Check-In and Payment options for Conference -->
              <div class="mb-4 flex space-x-4">
                <div>
                  <label
                    for="checkin"
                    class="block text-sm font-medium text-gray-700"
                    >Enable Check-In</label
                  >
                  <input
                    type="checkbox"
                    id="checkin"
                    v-model="enableConferenceCheckin"
                    @change="updateCheckinDays"
                    class="mt-1"
                  />
                </div>
                <div>
                  <label
                    for="payment"
                    class="block text-sm font-medium text-gray-700"
                    >Enable Payment</label
                  >
                  <input
                    type="checkbox"
                    id="payment"
                    v-model="enablePayment"
                    class="mt-1"
                  />
                </div>
              </div>

              <!-- Check-In Days selection for Conference -->
              <!-- <template v-if="enableConferenceCheckin">
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700"
                    >Select Check-In Days</label
                  >
                  <select
                    v-model="selectedConferenceCheckinDays"
                    multiple
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                    style="height: 100px; overflow-y: auto"
                  >
                    <option
                      v-for="(day, index) in checkinDays"
                      :key="index"
                      :value="day"
                    >
                      {{ day }}
                    </option>
                  </select>
                </div>
              </template> -->
            </template>

            <!-- Conditional field for Payment -->
            <template v-if="!isConference">
              <!-- Duration for Payment -->
              <div class="mb-4">
                <label
                  for="duration"
                  class="block text-sm font-medium text-gray-700"
                  >Duration</label
                >
                <div class="pt-2 flex">
                  <flat-pickr
                    v-model="paymentStartDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="Start date"
                    :config="paymentConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                  <span class="mx-4 text-gray-500">to</span>
                  <flat-pickr
                    v-model="paymentEndDate"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
                    placeholder="End date"
                    :config="paymentConfig"
                    @change="handleDateChange"
                  ></flat-pickr>
                </div>
              </div>

              <!-- Check-In option for Payment -->
              <div class="mb-4 pt-2">
                <label
                  for="checkin"
                  class="block text-sm font-medium text-gray-700"
                  >Enable Check-In</label
                >
                <input
                  type="checkbox"
                  id="checkin"
                  v-model="enablePaymentCheckin"
                  @change="updateCheckinDays"
                  class="mt-1"
                />
              </div>

              <!-- Check-In Days selection for Payment -->
              <template v-if="enablePaymentCheckin">
                <div class="mb-4">
                  <label class="block text-sm font-medium text-gray-700"
                    >Select Check-In Days</label
                  >
                  <select
                    v-model="selectedPaymentCheckinDays"
                    multiple
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 bg-gray-50 sm:text-sm"
                    style="height: 100px; overflow-y: auto"
                  >
                    <option
                      v-for="(day, index) in checkinDays"
                      :key="index"
                      :value="day"
                    >
                      {{ day }}
                    </option>
                  </select>
                </div>
              </template>
            </template>
          </div>
        </div>

        <!-- Modal footer -->
        <div
          class="sticky bottom-0 left-0 right-0 bg-white border-t border-gray-200 rounded-b"
        >
          <div
            class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b"
          >
            <button
              @click="generateLink"
              :disabled="isGenerateLinkButtonDisabled"
              type="button"
              class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Generate Link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

 <!-- Generated link modal -->
<div
  v-if="linkModalOpen"
  class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
>
  <div class="relative p-4 w-full max-w-md mx-auto">
    <div class="relative bg-white rounded-lg shadow-lg overflow-y-auto max-h-full">
      <div class="flex items-center justify-between p-4 border-b rounded-t">
        <h3 class="text-xl font-semibold text-red-600">Generated Link</h3>
        <button
          @click="toggleLinkModal"
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
        >
          X
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <div class="p-4 md:p-5">
        <p class="text-gray-700">Here's your generated link:</p>
        <p>
          <a :href="generatedLink" target="_blank">{{ generatedLink }}</a>
        </p>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { API_URL } from '../config';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import { useNotificationStore } from "@/stores/notificationStore"; // <--- ADDED

export default {
  name: "modalComp",
  components: {
    flatPickr,
  },

  data() {
    return {
      modalOpen: false,
      linkModalOpen: false,
      isConference: true,
      name: "",
      venue: "",
      startDate: null,
      endDate: null,
      paymentStartDate: null,
      paymentEndDate: null,
      enableConferenceCheckin: false,
      enablePaymentCheckin: false,
      enablePayment: false,
      generatedLink: "",
      selectedConferenceCheckinDays: [],
      selectedPaymentCheckinDays: [],
      checkinDays: [],
      conferenceConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      paymentConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
    };  
  },

    // Added
  setup() {
    const notificationStore = useNotificationStore();
     return {
      notificationStore,
    };
  },   
  
  computed: {
    isGenerateLinkButtonDisabled() {
      return this.isConference
        ? !this.name || !this.startDate || !this.endDate || !this.venue
        : !this.name ||
            !this.paymentStartDate ||
            !this.paymentEndDate ||
            !this.venue;
    },
  },

  watch: {
    startDate() {
      this.updateCheckinDays();
      if (
        this.startDate &&
        (!this.endDate || new Date(this.startDate) > new Date(this.endDate))
      ) {
        this.endDate = this.startDate;
      }
    },
    endDate() {
      this.updateCheckinDays();
    },
    paymentStartDate() {
      this.updateCheckinDays();
      if (
        this.paymentStartDate &&
        (!this.paymentEndDate ||
          new Date(this.paymentStartDate) > new Date(this.paymentEndDate))
      ) {
        this.paymentEndDate = this.paymentStartDate;
      }
    },
    paymentEndDate() {
      this.updateCheckinDays();
    },
  },

  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
      if (!this.modalOpen) {
        this.clearInputs();
    }
    },
    toggleLinkModal() {
      this.linkModalOpen = !this.linkModalOpen;
    },
    resetModal() {
    // Clear inputs and close the modal
    this.clearInputs();
    this.modalOpen = false;
    console.log("Modal has been reset!");
    },

    clearInputs() {
    console.log("Clearing inputs...");
    this.name = "";
    this.venue = "";
    this.startDate = null;
    this.endDate = null;
    this.paymentStartDate = null;
    this.paymentEndDate = null;
    this.enableConferenceCheckin = false;
    this.enablePaymentCheckin = false;
    this.enablePayment = false;
    this.checkinDays = [];
    this.selectedConferenceCheckinDays = [];
    this.selectedPaymentCheckinDays = [];
    console.log("Inputs cleared!");
    },

    handleDateChange() {
      if (this.isConference) {
        if (
          this.startDate &&
          (!this.endDate || new Date(this.startDate) > new Date(this.endDate))
        ) {
          this.endDate = this.startDate;
        }
      } else {
        if (
          this.paymentStartDate &&
          (!this.paymentEndDate ||
            new Date(this.paymentStartDate) > new Date(this.paymentEndDate))
        ) {
          this.paymentEndDate = this.paymentStartDate;
        }
      }
    },

    updateCheckinDays() {
      const startDate = this.isConference
        ? this.startDate
        : this.paymentStartDate;
      const endDate = this.isConference ? this.endDate : this.paymentEndDate;

      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const days = [];

        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          days.push(new Date(d).toLocaleDateString());
        }
        this.checkinDays = days; // Store all check-in days
        console.log("Automatically generated check-in days:", days);
      } else {
        this.checkinDays = [];
      }
    },

    generateLink() {
  // Prepare data based on whether it’s a conference or payment
  const recordData = {
    name: this.name,
    startDate: this.isConference ? this.startDate : this.paymentStartDate,
    endDate: this.isConference ? this.endDate : this.paymentEndDate,
    venue: this.venue,
    type: this.isConference ? "conference" : "payment",
    enablePayment: this.enablePayment,
    enableCheckIn: this.isConference
      ? this.enableConferenceCheckin
      : this.enablePaymentCheckin,
    checkinDays: this.checkinDays,
  };

  console.log("Record data being sent to API:", recordData);

  axios.post(`${API_URL}/api/createRecord`, recordData)
    .then(response => {
      console.log("Backend response:", response.data);

      const { recordId, recordName, link, paymentLink } = response.data;

      // Emit the new conference details to the parent component
      this.$emit('conferenceCreated', {
        id: recordId,
        name: recordData.name,
        date: recordData.startDate,
        endDate: recordData.endDate,
        venue: recordData.venue,
        enableCheckIn: recordData.enableCheckIn,
        enablePayment: recordData.enablePayment,
      });

      if (!recordId || !recordName) {
        console.error("Missing data in API response:", response.data);
        return;
      }

      // Log paymentRecordId directly if it exists in the response
      if (response.data.paymentRecordId) {
        console.log("Payment Record ID:", response.data.paymentRecordId);
      } else {
        console.log("No Payment Record ID in response");
      }

      // Determine which link to display based on enablePayment
      this.generatedLink = paymentLink || link;
      console.log("Final Generated Link:", this.generatedLink);

      // Open the modal to show the generated link
      this.linkModalOpen = true;

      // Added
      this.$notify({
            type: "success",
            message: `${this.isConference ? 'Conference' : 'Payment'} created successfully!`,
          });
          // Also push to Pinia notification store
          this.notificationStore.addNotification(
            'success',
            `${this.isConference ? 'Conference' : 'Payment'} created successfully!`
          );

      // Clear inputs and close the main modal
      this.resetModal();
    })
    .catch((error) => {
      console.error("Error generating link:", error);
    });
    },
  },
};
</script>

<style scoped></style>
