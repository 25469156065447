<template>
  <div class="flex">
    <div class="relative w-[1rem] bg-gradient-to-b from-orange-200 to-red-200 shadow-md h-[100vh]"></div>
    <div class="p-5 space-x-6 w-[100vw] bg-gradient-to-r from-white-100 to-white-100 h-[100vh] flex justify-center items-center">
      <div class="p-4 space-y-2 md:space-y-2 sm:p-2">
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 class="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Sign Up for an Account</h2>
          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" @submit.prevent="onSubmit"> <!-- Updated to connect with onSubmit -->
              <!-- Username Field -->
              <div>
                <label for="username" class="block text-sm font-medium leading-6 text-gray-900">Username</label>
                <div class="mt-2">
                  <input 
                    id="username" 
                    name="username" 
                    v-model="formData.username" 
                    type="text" 
                    autocomplete="username" 
                    required 
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" 
                  />
                </div>
              </div>

              <!-- Email Field -->
              <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div class="mt-2">
                  <input 
                    id="email" 
                    name="email" 
                    v-model="formData.email" 
                    type="email" 
                    autocomplete="email" 
                    required 
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" 
                  />
                </div>
                <div class="mt-2" v-if="errors.email">
                  <p class="text-sm text-red-600">{{ errors.email }}</p>
                </div>
              </div>

              <!-- Password Field -->
              <div>
                <div class="flex items-center justify-between">
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                </div>
                <div class="mt-2 relative">
                  <input 
                    :type="showPassword ? 'text' : 'password'" 
                    id="password" 
                    name="password" 
                    v-model="formData.password" 
                    autocomplete="current-password" 
                    required 
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" 
                  />
                  <button 
                    type="button" 
                    @click="togglePasswordVisibility" 
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900 focus:outline-none"
                  >
                    {{ showPassword ? 'Hide' : 'Show' }}
                  </button>
                </div>
                <div class="mt-2" v-if="errors.password">
                  <p class="text-sm text-red-600">{{ errors.password }}</p>
                </div>
              </div>

              <!-- Submit Button -->
              <div>
                <button 
                  type="submit" 
                  class="flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  :disabled="loading"
                  >
                  <span v-if="loading" class="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full"></span>
                  <span v-else>Sign Up</span>
                </button>
              </div>
            </form>
            <p class="mt-10 text-center text-sm text-gray-500">
              Already have an account?
              <button @click="goToLoginPage" class="font-semibold leading-6 text-red-600 hover:red-red-500 hover:underline">Sign In</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../../config';

export default {
  name: 'signup_page',
  data() {
    return {
      errors: {
        email: null,
        password: null,
      },
      formData: {
        username: '',
        email: '',
        password: '',
      },
      showPassword: false,
      loading: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    goToLoginPage() {
      this.$router.push('/Login_page');
    },
    validateEmail(email) {
      const emailPattern = /\S+@\S+\.\S+/;
      return emailPattern.test(email);
    },
    onSubmit() {
      this.loading = true; 
      axios.post(`${API_URL}/api/auth/signup`, this.formData)
        .then(response => {
          console.log('User registered successfully!', response.data);
          this.$router.push('/Login_page'); // Redirect to login page
        })
        .catch(error => {
          console.error('Error registering user:', error.response?.data || error.message);
          if (error.response?.data?.email) {
            this.errors.email = error.response.data.email;
          }
          if (error.response?.data?.password) {
            this.errors.password = error.response.data.password;
          }
        })
        .finally(() => {
          this.loading = false; // Reset loading state
        });
    },
  },
};
</script>
