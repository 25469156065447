<template>
  <div>
    <!-- Font Awesome Icon to Toggle Navbar, Always Visible -->
    <font-awesome-icon 
      :icon="['fas', 'bars']" 
      style="color: #000000; cursor: pointer; position: fixed; top: 18px; left: 16px; padding: 6px; z-index: 1002;" 
      @click="toggleNav"
    />

    <!-- Darkened Overlay -->
    <div v-if="navVisible" class="overlay" @click="toggleNav"></div>

    <!-- Vertical Navigation Bar -->
    <div :class="{'vertical-nav': true, 'visible': navVisible}">
      <ul>
        <li style="margin-top: 40px;"> 
          <!-- <button @click="goToDashboardPage">Dashboard</button> -->
        </li> 
        <li :class="{active: currentPage === 'home'}" @click="goToHomePage">
          <button>Home</button>
        </li>
        <!-- <li :class="{active: currentPage === 'event'}" @click="goToEventPage">
          <button>Event</button>
        </li> -->
        <li :class="{active: currentPage === 'reimbursement'}" @click="goToReimbursementPage">
          <button>Reimbursement</button>
        </li>
         <li class="logout">
          <button @click="logout">Logout</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navVisible: false,
      currentPage: '', // to track which page is selected
    };
  },
  methods: {
    toggleNav() {
      this.navVisible = !this.navVisible;
    },
    
    // goToDashboardPage() {
    //   this.$router.push("/Dashboard");
    // },

    // goToEventPage() {
    //   this.$router.push("/Event_page");
    // },

    goToHomePage() {
      this.$router.push("/Home_page");
    },

    goToReimbursementPage() {
      this.$router.push("/reimbursement_page");
    },

    logout() {
      // Clear authentication token or perform logout logic
      localStorage.removeItem("authToken");
      this.$router.push("/Login_page"); // Redirect to login page
    },
  },
  
  mounted() {
    const token = localStorage.getItem("authToken");
    if (!token) {
      this.$router.push("/Login_page"); // Redirect to login if not authenticated
    }
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Slight darkening */
  backdrop-filter: blur(5px); /* Blurring effect */
  z-index: 1000; /* Place below the navbar but above content */
  transition: all 0.3s ease-in-out;
}

/* Vertical Navigation Styles */
.vertical-nav {
  width: 200px;
  background-color: #ffffff;
  color: black;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -200px; /* Start hidden off-screen */
  transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
  z-index: 1001; /* Ensure the menu is above the blurred content */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Add a slight shadow to the right */
}

.vertical-nav.visible {
  left: 0; /* Move into view when visible */
}

.vertical-nav ul {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the list takes the full height of the nav bar */
  margin: 0;
  padding: 0;
  list-style: none; /* Remove default list styling */
}


.vertical-nav ul li {
  padding: 10px;
  text-align: left; /* Align text to the left */
  padding-left: 20px; /* Add some padding to the left for spacing */
}

.vertical-nav ul li.logout {
  margin-top: auto; /* Push the logout button to the bottom */
}

.vertical-nav ul li button {
  background: none;
  border: none;
  color: black;
  text-align: left;
  width: 100%;
  padding: 0.5px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  border-radius: 8px; 
}

.vertical-nav ul li:hover button {
  background-color: #f8d7da; /* Light red background on hover */
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px; /* Keep rounded corners on hover */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.vertical-nav ul li.active button {
  background-color: #f8d7da; /* Light red background for active item */
  color: #c82333; /* Darker red text for active item */
  border: 2px solid #c82333; /* Add a solid border around the active item */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow to highlight it */
  border-radius: 8px; /* Keep rounded corners */
}

/* Blur effect applied to the background content */
.blurred-background {
  filter: blur(5px); /* Apply blur only to the background content */
  transition: filter 0.3s ease-in-out;
}

/* Background content to simulate the page content behind the nav bar */
.background-content {
  padding: 20px;
  height: 100vh;
  background-color: #f0f0f0;
  z-index: 1;
}

/* Ensure the menu icon remains visible */
font-awesome-icon {
  z-index: 1002;
  position: fixed;
  top: 6px;
  left: 6px;
}

/* Prevent the menu itself from being blurred */
.vertical-nav {
  filter: none;
}
</style>
