<template>
  <div class="w-full h-16 bg-white shadow-md flex items-center justify-end px-5 relative">
    <div class="flex items-center space-x-4">
      <!-- Search Input -->
      <div class="relative">
        <el-input
          v-model="searchQuery"
          placeholder="Search records..."
          class="w-64"
          @input="onInputChange"
          clearable
        >
          <template #prefix>
            <el-icon class="text-gray-400">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>

      <!-- Notification Bell -->
      <div class="relative">
        <div class="cursor-pointer" @click="toggleNotifications">
          <i class="fa-regular fa-bell text-gray-600"></i>
          <span v-if="activeNotifications.length > 0"
                class="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1">
            {{ activeNotifications.length }}
          </span>
        </div>
        <div v-if="showNotifications"
             class="absolute top-full mt-2 right-0 w-65 bg-white shadow-lg rounded-md p-4 z-50"
             style="transform: translateX(50px)">
          <h3 class="text-lg font-semibold">Notifications</h3>
          <ul class="mt-2 divide-y divide-gray-200">
            <li v-for="notification in activeNotifications"
                :key="notification.id"
                class="py-2 px-3 flex justify-between items-start"
                :class="{
                  'bg-blue-100': notification.type === 'new',
                  'bg-green-100': notification.type === 'reminder',
                  'bg-red-100': notification.type === 'deleted',
                }">
              <div>
                <p class="text-sm font-bold capitalize">{{ notification.type }}</p>
                <p class="text-xs text-gray-600">{{ notification.message }}</p>
                <p class="text-xs text-gray-400">
                  {{ new Date(notification.timestamp).toLocaleString() }}
                </p>
              </div>
              <button class="text-xs text-red-500 hover:underline" @click="removeNotification(notification.id)">
                Dismiss
              </button>
            </li>
          </ul>
          <p v-if="activeNotifications.length === 0" class="mt-4 text-center text-gray-500">
            No notifications
          </p>
        </div>
      </div>
      <span>{{ username }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../config';
import { ref, watch, onMounted, onUnmounted, computed, nextTick } from "vue";
import { useNotificationStore } from "@/stores/notificationStore";
import { storeToRefs } from "pinia";
import { Search } from '@element-plus/icons-vue';
import { ElIcon } from 'element-plus';
import { useRoute } from 'vue-router';

// Pinia Search Store
import { useSearchStore } from "@/stores/searchStore";

export default {
  components: { Search, ElIcon },
  setup() {
    const username = ref("Loading...");

    // Fetch the username from the backend
  const fetchUsername = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) throw new Error("No auth token available");

    const response = await axios.get(`${API_URL}/api/auth/username`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    username.value = response.data.username;
  } catch (error) {
    if (error.response?.status === 403) {
      console.error("Token is invalid or expired. Please log out and log in.");
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
    } else {
      console.error("Error fetching username:", error.message);
      username.value = "Error";
    }
  }
};

    onMounted(() => {
      fetchUsername();
    });

    // Notification Store
    const notificationStore = useNotificationStore();
    const { activeNotifications } = storeToRefs(notificationStore);

    // Pinia: search store
    const searchStore = useSearchStore();

    // Computed that reads/writes to the store
    const searchQuery = computed({
      get: () => searchStore.query,
      set: (val) => searchStore.setQuery(val),
    });

    const showNotifications = ref(false);
    const autoHideTimer = ref(null);

    // local references for fetched data
    const conferences = ref([]);
    const payments = ref([]);
    const loading = ref(false);

    const route = useRoute();
    const currentPage = computed(() => route.name);

    // Highlight logic references 'searchQuery.value' from the store
    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function removeHighlights(container) {
      const marks = container.querySelectorAll('mark');
      marks.forEach(mark => {
        const text = document.createTextNode(mark.textContent);
        mark.parentNode.replaceChild(text, mark);
      });
    }

    function highlightMatches(container, query) {
      if (!query.trim()) return;
      removeHighlights(container);
      const escapedQuery = escapeRegExp(query.trim());
      const regex = new RegExp(`(${escapedQuery})`, 'gi');

      function processNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent;
          if (text.match(regex)) {
            const wrapper = document.createElement('span');
            wrapper.innerHTML = text.replace(regex, '<mark>$1</mark>');
            node.parentNode.replaceChild(wrapper, node);
          }
        } else {
          if (node.nodeName !== 'MARK') {
            Array.from(node.childNodes).forEach(processNode);
          }
        }
      }
      processNode(container);
    }

    const highlightedMatches = ref([]);
    const currentMatchIndex = ref(0);

    const gatherAllMarks = () => {
      highlightedMatches.value = [];
      const container = document.body;
      const marks = container.querySelectorAll('mark');
      marks.forEach(m => highlightedMatches.value.push(m));
      highlightCurrentMatch();
    };

    const highlightCurrentMatch = () => {
      highlightedMatches.value.forEach(m => m.classList.remove('current-match'));
      if (highlightedMatches.value.length === 0) return;
      const currentMark = highlightedMatches.value[currentMatchIndex.value];
      currentMark.classList.add('current-match');
      currentMark.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const performSearch = () => {
      const container = document.body;
      removeHighlights(container);
      if (searchQuery.value.trim()) {
        highlightMatches(container, searchQuery.value);
        nextTick(() => {
          gatherAllMarks();
        });
      } else {
        highlightedMatches.value = [];
        currentMatchIndex.value = 0;
      }
    };

    const debounce = (fn, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => fn(...args), delay);
      };
    };

    const handleSearch = debounce(performSearch, 300);

    // Simplified onInPut Change: it just calls handleSearch (the text is in store)
    const onInputChange = () => {
      console.log("Input changed:", searchQuery.value);
      handleSearch();
    };

    const goToNextMatch = () => {
      if (highlightedMatches.value.length === 0) return;
      currentMatchIndex.value = (currentMatchIndex.value + 1) % highlightedMatches.value.length;
      highlightCurrentMatch();
    };

    const goToPreviousMatch = () => {
      if (highlightedMatches.value.length === 0) return;
      currentMatchIndex.value = (currentMatchIndex.value - 1 + highlightedMatches.value.length) % highlightedMatches.value.length;
      highlightCurrentMatch();
    };

    // For debugging
    const displayType = (type) => {
      if (type === 'conference') return 'Conference';
      if (type === 'payment') return 'Payment';
      return '';
    };

    async function fetchData() {
      loading.value = true;
      try {
        console.log("Fetching data...");
        const [conferenceResponse, paymentResponse] = await Promise.all([
          axios.get(`${API_URL}/api/by_date`),
          axios.get(`${API_URL}/api/payments/sortedByDate`),
        ]);
        console.log("Raw conference response:", conferenceResponse.data);
        console.log("Raw payment response:", paymentResponse.data);

        conferences.value = Array.isArray(conferenceResponse.data.records)
          ? conferenceResponse.data.records.map(item => ({
              id: item.id,
              name: item.name || "Unnamed Conference",
              type: "conference",
            }))
          : [];

        payments.value = Array.isArray(paymentResponse.data.records)
          ? paymentResponse.data.records.map(item => ({
              id: item.id,
              name: item.name || "Unnamed Payment",
              type: "payment",
            }))
          : [];

        // Combine data into the store so other pages can also reference them if needed
        searchStore.setRecords([...conferences.value, ...payments.value]);

      } catch (error) {
        console.error("Error fetching data:", error);
        conferences.value = [];
        payments.value = [];
      } finally {
        loading.value = false;
      }
    }

    watch(route, () => {
      console.log("Route changed:", currentPage.value);
      performSearch();
    });

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        goToNextMatch();
      } else if (e.key === 'ArrowLeft') {
        goToPreviousMatch();
      }
    };

    onMounted(() => {
      fetchData();
      document.addEventListener('keydown', handleKeyDown);
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeyDown);
    });

    // Notification handling
    const hideNotifications = () => {
      showNotifications.value = false;
    };
    const resetAutoHideTimer = () => {
      if (autoHideTimer.value) clearTimeout(autoHideTimer.value);
      if (showNotifications.value) {
        autoHideTimer.value = setTimeout(hideNotifications, 5000);
      }
    };
    const toggleNotifications = () => {
      showNotifications.value = !showNotifications.value;
      if (showNotifications.value) notificationStore.clearUnreadCount();
      resetAutoHideTimer();
    };
    const removeNotification = (id) => {
      notificationStore.removeNotification(id);
      resetAutoHideTimer();
    };

    return {
      username,
      // from notification store
      activeNotifications,
      showNotifications,
      toggleNotifications,
      removeNotification,
      // from search store
      searchQuery,        // <--- used in template via v-model
      onInputChange,
      // highlight logic
      goToNextMatch,
      goToPreviousMatch,
      highlightedMatches,
      currentMatchIndex,
      // data
      conferences,
      payments,
      loading,
      currentPage,
      displayType,
    };
  }
};
</script>

<style scoped>
.highlight {
  background-color: yellow;
  transition: background-color 0.5s ease;
}

mark {
  background-color: yellow;
  padding: 0 2px;
}

.current-match {
  background-color: orange;
}

/* Override Element Plus input focus styles */
:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}

/* Alternatively, if you want a specific shade of red, you can use: */
:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #ff4d4f inset !important;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px #ff4d4f inset !important;
}

/* Override the default focus style for the input */
::v-deep(.el-input__inner:focus) {
  box-shadow: 0 0 0 0px #ff4d4f inset !important;
}

</style>